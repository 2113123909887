import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import Seo from '../components/Seo'
import BlockText from '../components/block-text/BlockText'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const PrivacyPolicyPage = ({ data, location }) => {
	const { siteUrl } = useSiteMetadata()

	const privacyPolicyData = data.wpgHeadlessQuery.acfBlockText
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout location={location} logo={data.logoImage}>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content:
							seoData.opengraphImage !== null
								? seoData.opengraphImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content:
							seoData.twitterImage !== null
								? seoData.twitterImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<BlockText
				title='Privacy Policy'
				content={privacyPolicyData.content}
			/>
		</Layout>
	)
}

export default PrivacyPolicyPage

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "privacy-policy-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfBlockText {
				content
			}
		}

		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 600)
			}
		}
	}
`
